import React, {createContext, useEffect, useState} from 'react';
import {useWallet} from 'use-wallet';
import OnyxFinance from '../../onyx-finance';
import config from '../../config';

export interface OnyxFinanceContext {
  onyxFinance?: OnyxFinance;
}

export const Context = createContext<OnyxFinanceContext>({onyxFinance: null});

export const OnyxFinanceProvider: React.FC = ({children}) => {
  const {ethereum, account} = useWallet();
  const [onyxFinance, setOnyxFinance] = useState<OnyxFinance>();

  useEffect(() => {
    if (!onyxFinance) {
      const onyx = new OnyxFinance(config);
      if (account) {
        // wallet was unlocked at initialization
        onyx.unlockWallet(ethereum, account);
      }
      setOnyxFinance(onyx);
    } else if (account) {
      onyxFinance.unlockWallet(ethereum, account);
    }
  }, [account, ethereum, onyxFinance]);

  return <Context.Provider value={{onyxFinance}}>{children}</Context.Provider>;
};
