export const ONYX_TICKER = 'ONYX';
export const OSHARE_TICKER = 'OSHARE';
export const OBOND_TICKER = 'OBOND';
export const MATIC_TICKER = 'MATIC';
export const WCRO_TICKER = 'WCRO';
export const ETH_TICKER = 'ETH';
export const USDC_TICKER = 'USDC';

export const SPOOKY_ROUTER_ADDR = '0x145677FC4d9b8F19B5D56d1820c48e0443049a30';
export const ZAPPER_ROUTER_ADDR = '0xc068dfBFbeA80D73F035123f9cb667FB79Bd2090';
export const TAX_OFFICE_ADDR = '0xaC61169C07725fc443A72a4e8df405ad451f5e5F';
