import {ChainId} from '@madmeerkat/sdk';
import {Configuration} from './onyx-finance/config';
import {BankInfo} from './onyx-finance';

const configurations: {[env: string]: Configuration} = {
  // development: {
  //   chainId: 97,
  //   networkName: 'CRONOS Testnet',
  //   ftmscanUrl: 'https://rpc-mumbai.maticvigil.com/',
  //   defaultProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  //   deployments: require('./onyx-finance/deployments/deployments.testing.json'),
  //   externalTokens: {
  //     WCRO: ['0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', 18],
  //     FUSDT: ['0x55d398326f99059fF775485246999027B3197955', 18], // This is actually BUSD on mainnet not fusdt
  //     ETH: ['0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378', 18],
  //     ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
  //     SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
  //     'USDT-MATIC-LP': ['0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827', 18],
  //     'ONYX-ETH-LP': ['0x497D4b031d1A7fB76B75C99Ad0F9c86DbA7657Fb', 18],
  //     'OSHARE-MATIC-LP': ['0xa90ccF2E01Be627ef0Ac1533d482E182D56ebe32', 18],
  //   },
  //   baseLaunchDate: new Date('2021-11-21 1:00:00Z'),
  //   bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
  //   boardroomLaunchesAt: new Date('2020-12-11T00:00:00Z'),
  //   refreshInterval: 10000,
  // },
  development: {
    chainId: 25,
    networkName: 'CRONOS Mainnet',
    ftmscanUrl: 'https://cronoscan.com/',
    defaultProvider: 'https://rpc.vvs.finance/',
    deployments: require('./onyx-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      // WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      USDT: ['0x66e428c3f67a68878562e79A0234c1F83c208770', 6],
      MMF: ['0x97749c9B61F878a880DfE312d2594AE07AEd7656', 18],
      GAUR: ['0x046cb616d7a52173e4Da9efF1BFd590550aa3228', 18],
      GSHARE: ['0x66ec6E9F61ac288f5BA661CD9a2dBe3aBf9871C9', 18],
      FUSDT: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 9], // This is actually BUSD on mainnet not fusdt USDT
      ETH: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      USDC: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6],
      CAKE: ['0x831753DD7087CaC61aB5644b308642cc1c33Dc13', 18],
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      NACHO: ['0xcD86152047e800d67BDf00A4c635A8B6C0e5C4c2', 18],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      'USDT-MATIC-LP': ['0xa68466208F1A3Eb21650320D2520ee8eBA5ba623', 18], //usdt-bnb USDC_WCRO
      'USDT-ETH-LP': ['0xa68466208F1A3Eb21650320D2520ee8eBA5ba623', 18], //eth-usdt 
      'ONYX-ETH-LP': ['0x4869F6d046c9f5AfA922FbdFF25a2E7e9157f4A9', 18],
      'ONYX-MATIC-LP': ['0xB6E85031F313563bF12ea414118978C8BD78db5D', 18],
      'ONYX-OSHARE-LP': ['0x4a3E704CCd751aFF30B104527c1bA482F40e4e5e', 18],
      'OSHARE-WCRO-LP': ['0xd6E2e8cb2E57f5d11152CcFf4a3071eF06D4BA87', 18],
      // 'OSHARE-MATIC-APELP': ['0x0dE2a71b2f43CF588A00422d41E1C02D0E08D552', 18],
      //https://rpc.cronaswap.org https://evm-cronos.crypto.org'
      // 'ONYX-WCRO-LP': ['0xB6E85031F313563bF12ea414118978C8BD78db5D', 18],
      'ONYX-WCRO-LP': ['0x4869F6d046c9f5AfA922FbdFF25a2E7e9157f4A9', 18],

    },
    baseLaunchDate: new Date('2022-02-07T03:00:00Z'),
    bondLaunchesAt: new Date('2022-02-20T03:00:00Z'),
    boardroomLaunchesAt: new Date('2022-02-02T06:00:00Z'),
    osharessLaunchesAt: new Date('2022-02-10T12:00:00Z'),
    refreshInterval: 10000,
  },
  production: {
    chainId: 25,
    networkName: 'CRONOS Mainnet',
    ftmscanUrl: 'https://cronoscan.com/',
    defaultProvider: 'https://rpc.vvs.finance/',
    deployments: require('./onyx-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18], //wmatic
      // WCRO: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      USDT: ['0x66e428c3f67a68878562e79A0234c1F83c208770', 6],
      MMF: ['0x97749c9B61F878a880DfE312d2594AE07AEd7656', 18],
      GAUR: ['0x046cb616d7a52173e4Da9efF1BFd590550aa3228', 18],
      GSHARE: ['0x66ec6E9F61ac288f5BA661CD9a2dBe3aBf9871C9', 18],
      FUSDT: ['0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', 9], // This is actually BUSD on mainnet not fusdt
      ETH: ['0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23', 18],
      USDC: ['0xc21223249CA28397B4B6541dfFaEcC539BfF0c59', 6],
      CAKE: ['0x831753DD7087CaC61aB5644b308642cc1c33Dc13', 18], //quick
      ZOO: ['0x09e145a1d53c0045f41aeef25d8ff982ae74dd56', 0],
      NACHO: ['0xcD86152047e800d67BDf00A4c635A8B6C0e5C4c2', 18],
      SHIBA: ['0x9ba3e4f84a34df4e08c112e1a0ff148b81655615', 9],
      'USDT-MATIC-LP': ['0xa68466208F1A3Eb21650320D2520ee8eBA5ba623', 18], //wmatic-USDT
      'USDT-ETH-LP': ['0xa68466208F1A3Eb21650320D2520ee8eBA5ba623', 18],
      'ONYX-ETH-LP': ['0x4869F6d046c9f5AfA922FbdFF25a2E7e9157f4A9', 18],
      'ONYX-MATIC-LP': ['0xB6E85031F313563bF12ea414118978C8BD78db5D', 18], //onyx-wmatic
      'OSHARE-WCRO-LP': ['0xd6E2e8cb2E57f5d11152CcFf4a3071eF06D4BA87', 18], //oshares-wmatic
      'ONYX-OSHARE-LP': ['0x4a3E704CCd751aFF30B104527c1bA482F40e4e5e', 18],
      // 'OSHARE-MATIC-APELP': ['0x0dE2a71b2f43CF588A00422d41E1C02D0E08D552', 18],
      'ONYX-WCRO-LP': ['0x4869F6d046c9f5AfA922FbdFF25a2E7e9157f4A9', 18],
    },
    baseLaunchDate: new Date('2022-02-07T03:00:00Z'),
    bondLaunchesAt: new Date('2022-02-02T03:00:00Z'),
    boardroomLaunchesAt: new Date('2022-02-02T06:00:00Z'),
    osharessLaunchesAt: new Date('2022-02-10T12:00:00Z'),
    refreshInterval: 10000,
  },
};

export const bankDefinitions: {[contractName: string]: BankInfo} = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding ONYX
        - 2 = LP asset staking rewarding OSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  // OnyxETHApeLPOnyxRewardPool: {
  //   name: 'Earn ONYX by ONYX-ETH Ape LP',
  //   poolId: 2,
  //   sectionInUI: 1,
  //   contract: 'OnyxETHApeLPOnyxRewardPool',
  //   depositTokenName: 'ONYX-WCRO-LP',
  //   earnTokenName: 'ONYX',
  //   finished: true,
  //   sort: 2,
  //   closedForStaking: true,
  // },



  // OnyxETHLPOnyxRewardPool: {
  //   name: 'Earn ONYX by ONYX-ETH LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'OnyxRewardPool',
  //   depositTokenName: 'ONYX-ETH-LP',
  //   earnTokenName: 'ONYX',
  //   finished: false,
  //   sort: 2,
  //   closedForStaking: false,
  // },


  
  // OnyxCakeRewardPool: {
  //   name: 'Earn ONYX by CAKE',
  //   poolId: 0,
  //   sectionInUI: 0,
  //   contract: 'OnyxCAKERewardPool',
  //   depositTokenName: 'CAKE',
  //   earnTokenName: 'ONYX',
  //   finished: true,
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // OnyxSETHRewardPool: {
  //   name: 'Earn ONYX by SETH',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'OnyxSETHRewardPool',
  //   depositTokenName: 'SETH',
  //   earnTokenName: 'ONYX',
  //   finished: true,
  //   sort: 4,
  //   closedForStaking: true,
  // },
  // OnyxSUSDRewardPool: {
  //   name: 'Earn ONYX by SUSD',
  //   poolId: 1,
  //   sectionInUI: 0,
  //   contract: 'OnyxSUSDRewardPool',
  //   depositTokenName: 'SUSD',
  //   earnTokenName: 'ONYX',
  //   finished: true,
  //   sort: 5,
  //   closedForStaking: true,
  // },
  // OnyxSVLRewardPool: {
  //   name: 'Earn ONYX by SVL',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'OnyxSVLRewardPool',
  //   depositTokenName: 'SVL',
  //   earnTokenName: 'ONYX',
  //   finished: true,
  //   sort: 6,
  //   closedForStaking: true,
  // },

  // OnyxGenesisRewardPool: {
  //   name: 'Earn ONYX by ETH',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'OnyxGenesisRewardPool',
  //   depositTokenName: 'ETH',
  //   earnTokenName: 'ONYX',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: false,
  // },

  

 
  OnyxWCROLPGenesisRewardPool: {
    name: 'Earn ONYX by ONYX-WCRO-LP',
    poolId: 0,
    sectionInUI: 0,
    contract: 'OnyxWCROLPGenesisRewardPool',
    depositTokenName: 'ONYX-WCRO-LP',
    earnTokenName: 'ONYX',
    finished: false,
    sort: 1,
    closedForStaking: false,
    DepositFee: '0%',
    AllocationPoint: '18k',
    buyLink: 'https://mm.finance/add/0x259C675c33e7750016ca36122D4Aa2E4CB53Ed50/CRO',
  },
  OnyxWCROGenesisRewardPool: {
    name: 'Earn ONYX by WCRO',
    poolId: 1,
    sectionInUI: 0,
    contract: 'OnyxWCROGenesisRewardPool',
    depositTokenName: 'WCRO',
    earnTokenName: 'ONYX',
    finished: false,
    sort: 2,
    closedForStaking: false,
    DepositFee: '1%',
    AllocationPoint: '9.5k',
    buyLink: 'https://mm.finance/swap?outputCurrency=0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  },
  OnyxUSDTGenesisRewardPool: {
    name: 'Earn ONYX by USDT',
    poolId: 2,
    sectionInUI: 0,
    contract: 'OnyxUSDTGenesisRewardPool',
    depositTokenName: 'USDT',
    earnTokenName: 'ONYX',
    finished: false,
    sort: 3,
    closedForStaking: false,
    DepositFee: '1%',
    AllocationPoint: '9.5k',
    buyLink: 'https://mm.finance/swap?outputCurrency=0x66e428c3f67a68878562e79A0234c1F83c208770',
  },
  OnyxUSDCGenesisRewardPool: {
    name: 'Earn ONYX by USDC',
    poolId: 3,
    sectionInUI: 0,
    contract: 'OnyxUSDCGenesisRewardPool',
    depositTokenName: 'USDC',
    earnTokenName: 'ONYX',
    finished: false,
    sort: 4,
    closedForStaking: false,
    DepositFee: '1%',
    AllocationPoint: '9.5k',
    buyLink: 'https://mm.finance/swap?outputCurrency=0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  },
 
  OnyxGAURGenesisRewardPool: {
    name: 'Earn ONYX by GAUR',
    poolId: 4,
    sectionInUI: 0,
    contract: 'OnyxGAURGenesisRewardPool',
    depositTokenName: 'GAUR',
    earnTokenName: 'ONYX',
    finished: false,
    sort: 5,
    closedForStaking: false,
    DepositFee: '0% {Partnership}',
    AllocationPoint: '1k',
    buyLink: 'https://mm.finance/swap?outputCurrency=0x046cb616d7a52173e4Da9efF1BFd590550aa3228',
  },
  OnyxGShareGenesisRewardPool: {
    name: 'Earn ONYX by GSHARE',
    poolId: 5,
    sectionInUI: 0,
    contract: 'OnyxGShareGenesisRewardPool',
    depositTokenName: 'GSHARE',
    earnTokenName: 'ONYX',
    finished: false,
    sort: 6,
    closedForStaking: false,
    DepositFee: '0% {Partnership}',
    AllocationPoint: '0.5k',
    buyLink: 'https://mm.finance/swap?outputCurrency=0x66ec6E9F61ac288f5BA661CD9a2dBe3aBf9871C9',
  },
  // OnyxBnbLPRewardPool: {
  //   name: 'Earn ONYX by ONYX-MATIC LP',
  //   poolId: 1,
  //   sectionInUI: 1,
  //   contract: 'OnyxBnbLPRewardPool',
  //   depositTokenName: 'ONYX-MATIC-LP',
  //   earnTokenName: 'ONYX',
  //   finished: false,
  //   sort: 8,
  //   closedForStaking: false,
  // },
  // OnyxShibaRewardPool: {
  //   name: 'Earn ONYX by SHIBA',
  //   poolId: 2,
  //   sectionInUI: 0,
  //   contract: 'OnyxShibaGenesisRewardPool',
  //   depositTokenName: 'SHIBA',
  //   earnTokenName: 'ONYX',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: true,
  // },
  // OnyxZooRewardPool: {
  //   name: 'Earn ONYX by ZOO',
  //   poolId: 3,
  //   sectionInUI: 0,
  //   contract: 'OnyxZooGenesisRewardPool',
  //   depositTokenName: 'ZOO',
  //   earnTokenName: 'ONYX',
  //   finished: false,
  //   sort: 4,
  //   closedForStaking: true,
  // },

  // OnyxFtmLPOnyxRewardPoolOld: {
  //   name: 'Earn ONYX by ONYX-MATIC LP',
  //   poolId: 0,
  //   sectionInUI: 1,
  //   contract: 'OnyxFtmLpOnyxRewardPoolOld',
  //   depositTokenName: 'ONYX-MATIC-LP',
  //   earnTokenName: 'ONYX',
  //   finished: true,
  //   sort: 9,
  //   closedForStaking: true,
  // },
  // OnyxFtmLPOShareRewardPool: {
  //   name: 'Earn OSHARE by ONYX-MATIC LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'OnyxFtmLPOShareRewardPool',
  //   depositTokenName: 'ONYX-MATIC-LP',
  //   earnTokenName: 'OSHARE',
  //   finished: false,
  //   sort: 6,
  //   closedForStaking: false,
  // },



  // OShareBnbOShareRewardPool: {
  //   name: 'Earn OSHARE by OSHARE-MATIC LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'OShareBnbOShareRewardPool',
  //   depositTokenName: 'OSHARE-MATIC-LP',
  //   earnTokenName: 'OSHARE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // OnyxEthOShareRewardPool: {
  //   name: 'Earn OSHARE by ONYX-ETH LP',
  //   poolId: 0,
  //   sectionInUI: 2,
  //   contract: 'OnyxEthOShareRewardPool',
  //   depositTokenName: 'ONYX-ETH-LP',
  //   earnTokenName: 'OSHARE',
  //   finished: false,
  //   sort: 0,
  //   closedForStaking: false,
  // },



  // OnyxEthLPApeOShareRewardPool: {
  //   name: 'Earn OSHARE by ONYX-ETH LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'OnyxEthLPApeOShareRewardPool',
  //   depositTokenName: 'ONYX-ETH-LP',
  //   earnTokenName: 'OSHARE',
  //   finished: false,
  //   sort: 7,
  //   closedForStaking: false,
  // },
  // OshareBnbApeLPOShareRewardPool: {
  //   name: 'Earn OSHARE by OSHARE-MATIC Ape LP',
  //   poolId: 2,
  //   sectionInUI: 1,
  //   contract: 'OshareBnbApeLPOShareRewardPool',
  //   depositTokenName: 'OSHARE-MATIC-APELP',
  //   earnTokenName: 'OSHARE',
  //   finished: true,
  //   sort: 5,
  //   closedForStaking: true,
  // },
  // OnyxEthApeLPOShareRewardPool: {
  //   name: 'Earn OSHARE by ONYX-ETH Ape LP',
  //   poolId: 3,
  //   sectionInUI: 2,
  //   contract: 'OnyxEthApeLPOShareRewardPool',
  //   depositTokenName: 'ONYX-WCRO-LP',
  //   earnTokenName: 'OSHARE',
  //   finished: true,
  //   sort: 4,
  //   closedForStaking: true,
  // },
  
  OnyxWCROLPOShareRewardPool: {
    name: 'Earn OSHARE by ONYX-WCRO LP',
    poolId: 0,
    sectionInUI: 2,
    contract: 'OnyxWCROLPOShareRewardPool',
    depositTokenName: 'ONYX-WCRO-LP',
    earnTokenName: 'OSHARE',
    finished: false,
    sort: 1,
    closedForStaking: false,
    DepositFee: '0%',
    AllocationPoint: '39k',
    buyLink: 'https://mm.finance/add/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23/0x259C675c33e7750016ca36122D4Aa2E4CB53Ed50',
  },
  OshareWCROLPOShareRewardPool: {
    name: 'Earn OSHARE by OSHARE-WCRO LP',
    poolId: 1,
    sectionInUI: 2,
    contract: 'OshareWCROLPOShareRewardPool',
    depositTokenName: 'OSHARE-WCRO-LP',
    earnTokenName: 'OSHARE',
    finished: false,
    sort: 2,
    closedForStaking: false,
    DepositFee: '0%',
    AllocationPoint: '39k',
    buyLink: 'https://mm.finance/add/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23/0x8e569fDa38e5db154e552c08d1feee7323B81D86',
  },
  // OshareONYXLPOShareRewardPool: {
  //   name: 'Earn OSHARE by ONYX-OSHARE LP',
  //   poolId: 2,
  //   sectionInUI: 2,
  //   contract: 'OshareONYXLPOShareRewardPool',
  //   depositTokenName: 'ONYX-OSHARE-LP',
  //   earnTokenName: 'OSHARE',
  //   finished: false,
  //   sort: 3,
  //   closedForStaking: false,
  //   DepositFee: '0%',
  //   AllocationPoint: '20k',
  //   buyLink: 'https://mm.finance/add/0x259C675c33e7750016ca36122D4Aa2E4CB53Ed50/0x8e569fDa38e5db154e552c08d1feee7323B81D86',
  // },
};

export default configurations[process.env.NODE_ENV || 'development'];
