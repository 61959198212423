import React from 'react';

//Graveyard ecosystem logos
import onyxLogo from '../../assets/img/onyx-logo2.png';
import tShareLogo from '../../assets/img/oshares-final2.png';
import onyxLogoPNG from '../../assets/img/onyx-final3.png';
import tShareLogoPNG from '../../assets/img/oshares-final2.png';
import tBondLogo from '../../assets/img/obond.png';

import onyxFtmLpLogo from '../../assets/img/onyx-eth-lp.png';
import bshareFtmLpLogo from '../../assets/img/oshares-matic-lp.png';
import bshareonyxLpLogo from '../../assets/img/oshares-onyx-lp.png';

import bnbLogo from '../../assets/img/matic.png';
import btcLogo from '../../assets/img/eth-logo.png';
import usdcLogo from '../../assets/img/USDC.png';
import nachoLogo from '../../assets/img/nacho.png';
import wcroLogo from '../../assets/img/cro.png';
import usdtLogo from '../../assets/img/usdt.png';
import mmfLogo from '../../assets/img/mmf.png';
import gaurLogo from '../../assets/img/gaur.png';
import gshareLogo from '../../assets/img/gshare.png';



const logosBySymbol: {[title: string]: string} = {
  //Real tokens
  //=====================
  ONYX: onyxLogo,
  ONYXPNG: onyxLogoPNG,
  OSHAREPNG: tShareLogoPNG,
  OSHARE: tShareLogo,
  OBOND: tBondLogo,
  // WCRO: bnbLogo,
  WCRO: wcroLogo,
  USDT: usdtLogo,
  MMF: mmfLogo,
  GAUR: gaurLogo,
  GSHARE: gshareLogo,
  BOO: bnbLogo,
  SHIBA: bnbLogo,
  ZOO: bnbLogo,
  CAKE: bnbLogo,
  NACHO: nachoLogo,
  SUSD: bnbLogo,
  SETH: btcLogo,
  ETH: btcLogo,
  USDC: usdcLogo,
  SVL: bnbLogo,
  'ETH-MATIC-LP': onyxFtmLpLogo,
  'ONYX-ETH-LP': onyxFtmLpLogo,
  'OSHARE-WCRO-LP': bshareFtmLpLogo,
  'ONYX-OSHARE-LP': bshareonyxLpLogo,
  'OSHARE-MATIC-APELP': bshareFtmLpLogo,
  'ONYX-WCRO-LP': onyxFtmLpLogo,
};

type LogoProps = {
  symbol: string;
  size?: number;
};

const TokenSymbol: React.FC<LogoProps> = ({symbol, size = 85}) => {
  if (!logosBySymbol[symbol]) {
    throw new Error(`Invalid Token Logo symbol: ${symbol}`);
  }
  if(symbol === 'ONYX-WCRO-LP' || symbol === 'OSHARE-WCRO-LP' ){
    return <img src={logosBySymbol[symbol]} alt={`${symbol} Logo`} width={95} height={60} />;
  }else if(symbol === 'USDC' || symbol === 'WCRO'|| symbol === 'USDT' || symbol === 'GSHARE'  || symbol === 'GAUR'){
    return <img src={logosBySymbol[symbol]} alt={`${symbol} Logo`} width={65} height={65} />;
  }else
  return <img src={logosBySymbol[symbol]} alt={`${symbol} Logo`} width={size} height={size} />;
};

export default TokenSymbol;
